import { Routes, Route, Link } from "react-router-dom";
import Home from "./Home";
import About from "./About";
import Login from "./Login";
import Registration from "./Registration";
import Clients from "./Clients";
import "bulma/css/bulma.min.css";

const Layout = () => {
  return (
    <>
      <nav
        className="navbar is-primary"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a className="navbar-item" href="/"></a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <Link className="navbar-item" to="/">
              Inicio
            </Link>

            <Link className="navbar-item" to="/about">
              Acerca de
            </Link>
            <Link className="navbar-item" to="/clients">
              Clientes
            </Link>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <Link className="button is-light" to="/login">
                  Iniciar Sesión
                </Link>{" "}
                <Link className="button is-primary" to="/registration">
                  Registrarse
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div>
        <img src="/logo.png" alt="Logo" width="112" height="28" />
      </div>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/about" element={<About />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/registration" element={<Registration />} />
        </Routes>
      </div>
    </>
  );
};

export default Layout;
