import { useState } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleLogin = async (e) => {
    e.preventDefault();

    // Reset errors
    setErrors({ email: "", password: "" });

    // Validation
    let hasErrors = false;
    if (!email) {
      setErrors((prevState) => ({
        ...prevState,
        email: "El correo electrónico es obligatorio.",
      }));
      hasErrors = true;
    }
    if (email.length > 256) {
      setErrors((prevState) => ({
        ...prevState,
        email: "El correo electrónico no puede tener más de 256 caracteres.",
      }));
      hasErrors = true;
    }
    if (!password) {
      setErrors((prevState) => ({
        ...prevState,
        password: "La contraseña es obligatoria.",
      }));
      hasErrors = true;
    }
    if (password.length > 128) {
      setErrors((prevState) => ({
        ...prevState,
        password: "La contraseña no puede tener más de 128 caracteres.",
      }));
      hasErrors = true;
    }

    if (!hasErrors) {
      try {
        // Sign in with Firebase authentication
        /*********************************** */
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            //setUser(userCredential.user);
            // If successful, redirect to clients page
            navigate("/clients");
            console.log(
              "User logged in successfully and redirected to clients page!"
            );
          })
          .catch((error) => {
            console.log(error);
            const errorCode = error.code;
            const errorMessage = error.message;
          });

        /**************************************** */
      } catch (error) {
        // Handle Firebase authentication errors
        console.error("Error signing in:", error.message);
        setErrors((prevState) => ({
          ...prevState,
          email: "Error al iniciar sesión. Verifica tus credenciales.",
        }));
      }
    }
  };

  return (
    <div className="container">
      <h2 className="title">Inicio de Sesión</h2>
      <form onSubmit={handleLogin}>
        {/* Email field */}
        <div className="field">
          <label className="label">Correo Electrónico:</label>
          <div className="control">
            <input
              className={`input ${errors.email && "is-danger"}`}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {errors.email && <p className="help is-danger">{errors.email}</p>}
        </div>
        {/* Password field */}
        <div className="field">
          <label className="label">Contraseña:</label>
          <div className="control">
            <input
              className={`input ${errors.password && "is-danger"}`}
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          {errors.password && (
            <p className="help is-danger">{errors.password}</p>
          )}
        </div>
        {/* Submit button */}
        <div className="field">
          <div className="control">
            <button className="button is-primary" type="submit">
              Iniciar Sesión
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;
