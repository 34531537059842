import React, { useState } from "react";
import { auth } from "../firebase";
import {
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";

const RegistrationForm = () => {
  const [email, setEmail] = useState("");
  const [emailConfirmation, setEmailConfirmation] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Reset error and success messages
    setError(null);
    setSuccessMessage("");

    // Validation checks (same as before)
    // ...

    try {
      // Create Firebase authentication user

      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed up
          const user = userCredential.user;
          user.sendEmailVerification();
          console.log(user);
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ..
        });

      // If user creation is successful, display success message
      setSuccessMessage("¡Registro exitoso!");

      // Reset form fields
      setEmail("");
      setEmailConfirmation("");
      setPassword("");
      setPasswordConfirmation("");

      // Optionally, you can use the userCredential object to access the created user:
      // const user = userCredential.user;
    } catch (error) {
      // Display Firebase error message
      setError(error.message);
    }
  };

  return (
    <div className="container">
      <h2 className="title">Formulario de Registro</h2>
      {error && <p className="notification is-danger">{error}</p>}
      {successMessage && (
        <p className="notification is-success">{successMessage}</p>
      )}
      <form onSubmit={handleSubmit}>
        <div className="field">
          <label className="label">Correo Electrónico:</label>
          <div className="control">
            <input
              className="input"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Confirmar Correo Electrónico:</label>
          <div className="control">
            <input
              className="input"
              type="email"
              value={emailConfirmation}
              onChange={(e) => setEmailConfirmation(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Contraseña:</label>
          <div className="control">
            <input
              className="input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="field">
          <label className="label">Confirmar Contraseña:</label>
          <div className="control">
            <input
              className="input"
              type="password"
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.target.value)}
              required
            />
          </div>
        </div>
        <div className="field">
          <div className="control">
            <button className="button is-primary" type="submit">
              Registrarse
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
