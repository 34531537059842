import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCqdm_dy3TVGs5ETgVA-jukEtSUyvGt2hk",
  authDomain: "fotokiosco-e5d54.firebaseapp.com",
  projectId: "fotokiosco-e5d54",
  storageBucket: "fotokiosco-e5d54.appspot.com",
  messagingSenderId: "526853144242",
  appId: "1:526853144242:web:cb6bacae4b796af76853ab",
  measurementId: "G-9X02DMFVRS",
};

export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
